import styled from "styled-components";

export const RelatoriosContainer = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;

    @media (max-width: 1400px){
        padding: 20px;
        overflow-y: auto;
        overflow-x: hidden;
    }
`;

export const Title = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    text-align: center;
    font-size: 48px;
    color: white;

    @media (max-width: 1400px){
        font-size: 28px;
    }
`;

export const TypeSelection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding: 0 20px;
    box-sizing: border-box;
    gap: 20px;

    .selectionBox{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;   

        .titulo{
            font-size: 18px;
            color: white;
            font-weight: 600;
            text-shadow: 1px 1px 1px rgba(0,0,0,0.6);
            text-align: center;
        }

        .selectItem{
            width: 100%;
            height: 40px;
            border-radius: 3px;
            background: linear-gradient(to right, rgba(0, 0, 255, 0.5), rgba(0, 50, 255, 0.5));
            border: 0;
            color: white;
            box-sizing: border-box;
            padding-left: 20px;
            font-size: 18px;
        }


        .inputItem{
            width: 100%;
            height: 40px;
            border-radius: 3px;
            background: linear-gradient(to right, rgba(0, 0, 255, 0.5), rgba(0, 50, 255, 0.5));
            border: 0;
            color: white;
            box-sizing: border-box;
            padding-left: 20px;
            font-size: 18px;
        }
    }
`;


export const TypeSelection2 = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    padding: 0 20px;
    box-sizing: border-box;
    gap: 20px;

    .selectionBox{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;   

        .titulo{
            font-size: 18px;
            color: white;
            font-weight: 600;
            text-shadow: 1px 1px 1px rgba(0,0,0,0.6);
            text-align: center;
        }

        .selectItem{
            width: 100%;
            height: 40px;
            border-radius: 3px;
            background: linear-gradient(to right, rgba(0, 0, 255, 0.5), rgba(0, 50, 255, 0.5));
            border: 0;
            color: white;
            font-size: 18px;
        }
    }

`;

export const ButtonGenerate = styled.button`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    height: 30px;
    border: 0;
    border-radius: 3px;
    background: linear-gradient(to right, rgba(0, 200, 0, 1), rgba(50, 255, 0, 1));
    color: black;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    transition: .3s;

    &:hover{
        color: white;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.6);
    }
`;


export const TabelaDeContratos = styled.div`
    width: 100%;
    margin-top: 20px;
    max-height: 550px;
    overflow: auto;
`;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;

