import React, { useContext, useEffect, useState, useRef } from "react";
import * as S from "./RelatoriosStyle";
import { AuthContext } from "../../Context/AuthContext";
import helpers from "../../helpers";
import TabelaGeral from "../TabelaGeral/TabelaGeral";
import { useLoad } from "../../Context/LoadContext";


export default function Relatorios() {
    const { authState } = useContext(AuthContext);
    const [tipoRelatorio, setTipoRelatorio] = useState("selecione");
    const [filtroRelatorio, setFiltroRelatorio] = useState("null");
    const [desdeQuando, setDesdeQuando] = useState("null");
    const [relatorioData, setRelatorioData] = useState([]);
    const [idConsultor, setIdConsultor] = useState("");
    const { startLoading, stopLoadingDelay } = useLoad();

    const handleGeneratePDF = async () => {
        startLoading()
        var urlFiltragem = "";
        if (tipoRelatorio === "clients/") {

            if(idConsultor.trim() === "")
                urlFiltragem = "relatorio/clients";
            else urlFiltragem = `relatorio/clients/consultor/${idConsultor}`;

            if (desdeQuando != "null" || filtroRelatorio != "null" || idConsultor.trim() != "") {
                if (desdeQuando != "null" && filtroRelatorio === "null" && idConsultor.trim() === "") {
                    urlFiltragem = `relatorio/clients/creationDate/${desdeQuando}`
                } else if (desdeQuando != "null" && filtroRelatorio !== "null" && idConsultor.trim() === "") {
                    urlFiltragem = `relatorio/clients/creationDate/${desdeQuando}/status/${filtroRelatorio}`
                } else if (desdeQuando === "null" && filtroRelatorio != "null" && idConsultor.trim() === "") {
                    urlFiltragem = `relatorio/clients/status/${filtroRelatorio}`
                }

                else if (desdeQuando != "null" && filtroRelatorio !== "null" && idConsultor.trim() !== "") {
                    urlFiltragem = `relatorio/clients/creationDate/${desdeQuando}/status/${filtroRelatorio}/consultor/${idConsultor}`
                } else if (desdeQuando === "null" && filtroRelatorio != "null" && idConsultor.trim() !== "") {
                    urlFiltragem = `relatorio/clients/status/${filtroRelatorio}/consultor/${idConsultor}`
                }

                if (idConsultor.trim() != "") {
                    urlFiltragem += `/consultor/${idConsultor}`
                }
            }
        }

        if (tipoRelatorio === "consultor/") {
            urlFiltragem = "relatorio/consultor";

            if (desdeQuando != "null" || filtroRelatorio != "null") {
                if (desdeQuando != "null" && filtroRelatorio === "null") {
                    urlFiltragem = `relatorio/consultor/creationDate/${desdeQuando}`
                } else if (desdeQuando != "null" && filtroRelatorio !== "null") {
                    urlFiltragem = `relatorio/consultor/creationDate/${desdeQuando}/status/${filtroRelatorio}`
                } else if (desdeQuando === "null" && filtroRelatorio != "null") {
                    urlFiltragem = `relatorio/consultor/status/${filtroRelatorio}`
                }
            }
        }

        if (tipoRelatorio === "purchases/") {
            urlFiltragem = "relatorio/purchases";

            if (desdeQuando != "null" || filtroRelatorio != "null") {
                if (desdeQuando != "null" && filtroRelatorio === "null") {
                    urlFiltragem = `relatorio/purchases/creationDate/${desdeQuando}`
                } else if (desdeQuando != "null" && filtroRelatorio !== "null") {
                    urlFiltragem = `relatorio/purchases/creationDate/${desdeQuando}/status/${filtroRelatorio}`
                } else if (desdeQuando === "null" && filtroRelatorio != "null") {
                    urlFiltragem = `relatorio/purchases/status/${filtroRelatorio}`
                }
            }
        }
        if (tipoRelatorio === "withdrawals/") {
            urlFiltragem = "relatorio/withdrawals";

            if (desdeQuando != "null" || filtroRelatorio != "null") {
                if (desdeQuando != "null" && filtroRelatorio === "null") {
                    urlFiltragem = `relatorio/withdrawals/creationDate/${desdeQuando}`
                } else if (desdeQuando != "null" && filtroRelatorio !== "null") {
                    urlFiltragem = `relatorio/withdrawals/creationDate/${desdeQuando}/status/${filtroRelatorio}`
                } else if (desdeQuando === "null" && filtroRelatorio != "null") {
                    urlFiltragem = `relatorio/withdrawals/status/${filtroRelatorio}`
                }
            }
        }
        helpers.gerarRelatorioPdf(urlFiltragem, authState.token);
        stopLoadingDelay()
    }

    const handleGenerateData = async () => {
        startLoading()
        var urlFiltragem = "";

        if (tipoRelatorio === "clients/") {

            if(idConsultor.trim() === "")
                urlFiltragem = "relatorio/clients/noPdf";
            else urlFiltragem = `relatorio/clients/consultor/${idConsultor}/noPdf`;

            if (desdeQuando != "null" || filtroRelatorio != "null" ||idConsultor.trim() !== "") {
                if (desdeQuando != "null" && filtroRelatorio === "null" && idConsultor.trim() === "") {
                    urlFiltragem = `relatorio/clients/creationDate/${desdeQuando}/noPdf`
                } else if (desdeQuando != "null" && filtroRelatorio !== "null" && idConsultor.trim() === "") {
                    urlFiltragem = `relatorio/clients/creationDate/${desdeQuando}/status/${filtroRelatorio}/noPdf`
                } else if (desdeQuando === "null" && filtroRelatorio != "null" && idConsultor.trim() === "") {
                    urlFiltragem = `relatorio/clients/status/${filtroRelatorio}/noPdf`
                } else if (desdeQuando != "null" && filtroRelatorio === "null" && idConsultor.trim() !== ""){
                    urlFiltragem = `relatorio/clients/creationDate/${desdeQuando}/consultor/${idConsultor}/noPdf`
                }else if (desdeQuando === "null" && filtroRelatorio != "null" && idConsultor.trim() !== "") {
                    urlFiltragem = `relatorio/clients/status/${filtroRelatorio}/consultor/${idConsultor}/noPdf`
                } 
            }
        }

        if (tipoRelatorio === "consultor/") {
            urlFiltragem = "relatorio/consultor/noPdf";

            if (desdeQuando != "null" || filtroRelatorio != "null") {
                if (desdeQuando != "null" && filtroRelatorio === "null") {
                    urlFiltragem = `relatorio/consultor/creationDate/${desdeQuando}/noPdf`
                } else if (desdeQuando != "null" && filtroRelatorio !== "null") {
                    urlFiltragem = `relatorio/consultor/creationDate/${desdeQuando}/status/${filtroRelatorio}/noPdf`
                } else if (desdeQuando === "null" && filtroRelatorio != "null") {
                    urlFiltragem = `relatorio/consultor/status/${filtroRelatorio}/noPdf`
                }
            }
        }

        if (tipoRelatorio === "purchases/") {
            urlFiltragem = "relatorio/purchases/noPdf";

            if (desdeQuando != "null" || filtroRelatorio != "null") {
                if (desdeQuando != "null" && filtroRelatorio === "null") {
                    urlFiltragem = `relatorio/purchases/creationDate/${desdeQuando}/noPdf`
                } else if (desdeQuando != "null" && filtroRelatorio !== "null") {
                    urlFiltragem = `relatorio/purchases/creationDate/${desdeQuando}/status/${filtroRelatorio}/noPdf`
                } else if (desdeQuando === "null" && filtroRelatorio != "null") {
                    urlFiltragem = `relatorio/purchases/status/${filtroRelatorio}/noPdf`
                }
            }
        }
        if (tipoRelatorio === "withdrawals/") {
            urlFiltragem = "relatorio/withdrawals/noPdf";

            if (desdeQuando != "null" || filtroRelatorio != "null") {
                if (desdeQuando != "null" && filtroRelatorio === "null") {
                    urlFiltragem = `relatorio/withdrawals/creationDate/${desdeQuando}/noPdf`
                } else if (desdeQuando != "null" && filtroRelatorio !== "null") {
                    urlFiltragem = `relatorio/withdrawals/creationDate/${desdeQuando}/status/${filtroRelatorio}/noPdf`
                } else if (desdeQuando === "null" && filtroRelatorio != "null") {
                    urlFiltragem = `relatorio/withdrawals/status/${filtroRelatorio}/noPdf`
                }
            }
        }
        var itens = await helpers.gerarRelatorioData(urlFiltragem, authState.token);

        if (itens) {
            console.log(itens)
            setRelatorioData(itens)
        }
        stopLoadingDelay()
    }

    const columnsClientsRelatorio = [
        { name: "Nome", value: "name" },
        { name: "CPF", value: "id" },
        { name: "Data Cadastro", value: "dateCreated", formatFunction: helpers.formatDate },
        { name: "Consultor", value: "consultorId" },
    ]

    const columnsConsultoresRelatorio = [
        { name: "Nome", value: "name" },
        { name: "CPF", value: "id" },
        { name: "Data Cadastro", value: "dateCreated", formatFunction: helpers.formatDate },
        { name: "Quantidade Clientes", value: "clientsQtt" },
    ]

    const columnsPurchasesRelatorio = [
        { name: "Id", value: "purchaseId" },
        { name: "Id Cliente", value: "clientId" },
        { name: "Data Da Compra", value: "purchaseDate", formatFunction: helpers.formatDate },
        { name: "Data De Término", value: "endContractDate", formatFunction: helpers.formatDate },
        { name: "Valor Total", value: "totalPrice", insertStart: "R$ ", formatFunction: helpers.formatNumberToCurrency },
        { name: "Valor Pago", value: "amountPaid", insertStart: "R$ ", formatFunction: helpers.formatNumberToCurrency },
        { name: "Status", value: "status", formatFunction: helpers.handlePurchaseStatus },
    ]

    const columnsWithdrawalsRelatorio = [
        { name: "Id", value: "withdrawalId" },
        { name: "Id Cliente", value: "clientId" },
        { name: "Valor", value: "amountWithdrawn", insertStart: "R$ ", formatFunction: helpers.formatNumberToCurrency },
        { name: "Valor Recebível", value: "amountWithdrawnReceivable", insertStart: "R$ ", formatFunction: helpers.formatNumberToCurrency },
        { name: "Data do Saque", value: "dateCreated", formatFunction: helpers.formatDate },
        { name: "Status", value: "status", formatFunction: helpers.handleWithdrawalStatus }
    ]

    return (
        <>
            <S.RelatoriosContainer>
                <S.Title>Relatórios</S.Title>

                <S.TypeSelection>
                    <div className="selectionBox">
                        <span className="titulo">Selecione o Relatório</span>
                        <select value={tipoRelatorio} onChange={(e) => { setTipoRelatorio(e.target.value); setDesdeQuando("null"); setFiltroRelatorio("null"); setRelatorioData([]) }} className="selectItem">
                            <option value="selecione">Selecione</option>
                            <option value="clients/">Clientes</option>
                            <option value="consultor/">Consultores</option>
                            <option value="purchases/">Compras</option>
                            <option value="withdrawals/">Saques</option>
                        </select>
                    </div>

                </S.TypeSelection>
                <S.TypeSelection2>

                    {tipoRelatorio === "clients/" && (
                        <>
                            <div className="selectionBox">
                                <span className="titulo">Selecione o Filtro</span>
                                <select value={filtroRelatorio} onChange={(e) => { setFiltroRelatorio(e.target.value); setRelatorioData([]) }} className="selectItem">
                                    <option value="null">Todos</option>
                                    <option value="2">Inativos</option>
                                    <option value="1">Ativos</option>
                                </select>
                            </div>
                        </>
                    )}


                    {tipoRelatorio === "consultor/" && (
                        <>
                            <div className="selectionBox">
                                <span className="titulo">Selecione o Filtro</span>
                                <select value={filtroRelatorio} onChange={(e) => { setFiltroRelatorio(e.target.value); setRelatorioData([]) }} className="selectItem">
                                    <option value="null">Todos</option>
                                    <option value="2">Inativos</option>
                                    <option value="1">Ativos</option>
                                </select>
                            </div>
                        </>
                    )}

                    {tipoRelatorio === "purchases/" && (
                        <>
                            <div className="selectionBox">
                                <span className="titulo">Selecione o Filtro</span>
                                <select value={filtroRelatorio} onChange={(e) => { setFiltroRelatorio(e.target.value); setRelatorioData([]) }} className="selectItem">
                                    <option value="null">Todos</option>
                                    <option value="1">Pendentes</option>
                                    <option value="2">Valorizando</option>
                                    <option value="3">Finalizados</option>
                                    <option value="4">Cancelados</option>
                                </select>
                            </div>
                        </>
                    )}

                    {tipoRelatorio === "withdrawals/" && (
                        <>
                            <div className="selectionBox">
                                <span className="titulo">Selecione o Filtro</span>
                                <select value={filtroRelatorio} onChange={(e) => { setFiltroRelatorio(e.target.value); setRelatorioData([]) }} className="selectItem">
                                    <option value="null">Todos</option>
                                    <option value="1">Pendentes</option>
                                    <option value="2">Pagos</option>
                                    <option value="3">Cancelados</option>
                                </select>
                            </div>
                        </>
                    )}

                    {tipoRelatorio != "selecione" && (
                        <>
                            <div className="selectionBox">
                                <span className="titulo">De Quando</span>
                                <select value={desdeQuando} onChange={(e) => { setDesdeQuando(e.target.value); setRelatorioData([]) }} className="selectItem">
                                    <option value="null">Desde o Início</option>
                                    <option value="1">Hoje</option>
                                    <option value="30">Últimos 30 Dias</option>
                                    <option value="60">Último Bimestre</option>
                                    <option value="90">Último Trimestre</option>
                                    <option value="180">Último Semestre</option>
                                    <option value="365">Último Ano</option>
                                </select>
                            </div>
                        </>
                    )}

                    {tipoRelatorio == "clients/" && (
                        <>
                            <div className="selectionBox">
                                <span className="titulo">ID Consultor</span>
                                <input className="selectItem" placeholder="Digite aqui..." value={idConsultor} onChange={(e) => { setIdConsultor(e.target.value); setRelatorioData([]) }} />
                            </div>
                        </>
                    )}

                </S.TypeSelection2>

                {tipoRelatorio != "selecione" && (
                    <>
                        <S.ButtonGenerate onClick={handleGenerateData} className="gerarButton">Gerar Relatório</S.ButtonGenerate>
                    </>
                )}

                {relatorioData && (

                    <S.TabelaDeContratos>
                        <>
                            {tipoRelatorio === "clients/" && (
                                <>
                                    <TabelaGeral columns={columnsClientsRelatorio} data={relatorioData || []} type="clients" setSelectedThing={() => console.log("")} />
                                </>
                            )}

                            {tipoRelatorio === "consultor/" && (
                                <>
                                    <TabelaGeral columns={columnsConsultoresRelatorio} data={relatorioData || []} type="consultores" setSelectedThing={() => console.log("")} />
                                </>
                            )}

                            {tipoRelatorio === "purchases/" && (
                                <>
                                    <TabelaGeral columns={columnsPurchasesRelatorio || []} data={relatorioData || []} type="contracts" setSelectedThing={() => console.log("")} />
                                </>
                            )}

                            {tipoRelatorio === "withdrawals/" && (
                                <>
                                    <TabelaGeral columns={columnsWithdrawalsRelatorio || []} data={relatorioData || []} type="withdrawals" setSelectedThing={() => console.log("")} />
                                </>
                            )}
                        </>
                    </S.TabelaDeContratos>
                )}
                {tipoRelatorio != "selecione" && relatorioData && (
                    <>
                        <S.ButtonGenerate onClick={handleGeneratePDF} className="gerarButton">Baixar Relatório</S.ButtonGenerate>
                    </>
                )}
            </S.RelatoriosContainer>
        </>
    )
}