import styled from "styled-components";

export const AjudaContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    min-height: 100vh;

        padding: 40px 30px;
    box-sizing: border-box;
`;

export const Title = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    color: white;
    font-size: 32px;
    font-weight: 600;
`;

export const AjudaArea = styled.div`
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: start;
    border-top: 3px solid white;
    box-sizing: border-box;
    padding: 10px 0;
    gap: 20px;
`;

export const SelectionArea = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;

    .titleText{
        font-size: 28px;
        color: white;
    }

    .selectItem{
        width: 300px;
        height: 30px;
        border-radius: 3px;
        border: 0;
        background: rgba(0,0,0,0.4);
        color: rgba(0, 200, 255, 1);
        text-align: center;
        box-sizing: border-box;
        font-size: 16px;
    }

`;

export const VideoPart = styled.div`
    width: 100%;
    height: 600px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`;

// export const name = styled.div``;

// export const name = styled.div``;

// export const name = styled.div``;

