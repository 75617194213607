import { AuthContext } from "../../Context/AuthContext";
import { useLoad } from "../../Context/LoadContext";
import helpers from "../../helpers";
import * as S from "./ClientPageStyle";
import React, { useState, useEffect, useContext } from "react";
import ExcluirCliente from "./Funcoes/ExcluirCliente/ExcluirCliente";
import AdicionarSaldo from "./Funcoes/AdicionarSaldo/AdicionarSaldo";

export default function ClientPage({ handleClose, client, handleSelectClient }) {
    const { startLoading, stopLoading } = useLoad();
    const { authState, clients } = useContext(AuthContext);
    const [editStreet, setEditStreet] = useState(false);
    const [editStreetNumber, setEditStreetNumber] = useState(false);
    const [editNeighborhood, setEditNeighborhood] = useState(false);
    const [editZipcode, setEditZipcode] = useState(false);
    const [editCity, setEditCity] = useState(false);
    const [editState, setEditState] = useState(false);
    const [editCountry, setEditCountry] = useState(false);
    const [editIndicador, setEditIndicador] = useState(false);
    const [editConsultor, setEditConsultor] = useState(false);

    const [editedStreet, setEditedStreet] = useState("");
    const [editedStreetNumber, setEditedStreetNumber] = useState("");
    const [editedNeighborhood, setEditedNeighborhood] = useState("");
    const [editedZipcode, setEditedZipcode] = useState("");
    const [editedCity, setEditedCity] = useState("");
    const [editedState, setEditedState] = useState("");
    const [editedCountry, setEditedCountry] = useState("");
    const [editedIndicador, setEditedIndicador] = useState("");
    const [hoverOp, setHoverOp] = useState(false);
    const [changePass, setChangePass] = useState(false);
    const [seePass1, setSeePass1] = useState(false);
    const [seePass2, setSeePass2] = useState(false);
    const [pass1, setPass1] = useState("");
    const [pass2, setPass2] = useState("");
    const [excludeAccount, setExcludeAccount] = useState(false);
    const [adicionarSaldo, setAdicionarSaldo] = useState(false);
    const [editedConsultor, setEditedConsultor] = useState("");


    useEffect(() => {
        if (client) {
            startLoading();
            setEditedStreet(client.address.street);
            setEditedStreetNumber(client.address.number);
            setEditedNeighborhood(client.address.neighborhood);
            setEditedCity(client.address.city);
            setEditedZipcode(client.address.zipcode);
            setEditedCountry(client.address.country);
            setEditedState(client.address.state);
            setEditedIndicador(client.sponsorId || "");
            setEditedConsultor(client.consultorId || "");
            stopLoading()
        }
    }, [client]);

    const handleSaveAddress = () => {

        if (editedStreet.trim() != "" && editedStreetNumber.trim() != "" && editedNeighborhood.trim() != "" && editedZipcode.trim() != "" && editedCity.trim() != "") {
            helpers.editarEnderecoCliente(client.id, {
                street: editedStreet,
                number: editedStreetNumber,
                neighborhood: editedNeighborhood,
                city: editedCity,
                zipcode: editedZipcode,
                state: editedState,
                country: editedCountry
            }, authState.token)
            console.log("editando")
            setEditStreet(false);
            setEditStreetNumber(false);
            setEditNeighborhood(false);
            setEditCity(false);
            setEditZipcode(false);
            setEditCountry(false);
            setEditState(false);
        } else {
            alert("Preencha o campo antes de salvar.");
            return;
        }
    }

    const handleSaveIndicator = () => {

        if (editedIndicador.trim() != "") {
            helpers.editarSponsorCliente(client.id, editedIndicador, authState.token)
            setEditIndicador(false);
        } else {
            alert("Preencha o campo antes de salvar.");
            return;
        }
    }

    const handleSaveConsultor = () => {

        if (editedConsultor.trim() != "") {
            helpers.editarConsultorCliente(client.id, editedConsultor, authState.token)
            setEditConsultor(false);
        } else {
            alert("Preencha o campo antes de salvar.");
            return;
        }
    }

    const handleChangePass = async () => {
        if (pass1.trim() != "" && pass2.trim() != "") {
            if (pass1 === pass2) {
                if (helpers.isPasswordSecure(pass1)) {
                    startLoading();
                    const res = await helpers.changeClientPass(client.id, pass1, authState.token);

                    if (res) {
                        alert("Senha Alterada Com Sucesso");
                        setChangePass(false);
                        setPass1("")
                        setPass2("");
                        stopLoading();
                    } else {
                        alert("Erro ao Atualizar a Senha");
                        setPass1("")
                        setPass2("");
                        stopLoading();
                    }
                } else {
                    alert("A Senha Necessita de Pelo Menos 6 Caracteres, um Caractere Especial e Um Número.");
                    return;
                }
            } else {
                alert("As Senhas Não Se Coincidem");
                return;
            }
        } else {
            alert("Preencha as Senhas");
            return;
        }
    }

    const handleSelectSponsor = (sponsor) => {
        if (sponsor) {
            var clientS = clients.filter(c => c.email === sponsor)[0]
            if (clientS)
                handleSelectClient(clientS)
        }
    }

    return (
        <>
            <S.ClientPageContainer>
                <S.ClientBox>
                    <button className="exit" onClick={handleClose}>Sair</button>

                    <S.ProfilePhoto>
                        <div className="roundedBox">
                            <img src="profile-icon.png" />
                        </div>
                        <span className="clientName">{client.name && client.name}</span>
                    </S.ProfilePhoto>

                    <S.PrimaryInfo>
                        <S.ClientInfoBox>
                            <span className="fieldName">Nome</span>
                            <div className="valueBox">
                                <div className="icon"><img src="info-circle-icon.png" /></div>
                                <input value={client.name} style={{ width: "250px" }} className="inputItem" />
                            </div>
                        </S.ClientInfoBox>
                        <S.ClientInfoBox>
                            <span className="fieldName">CPF/CNPJ</span>
                            <div className="valueBox">
                                <div className="icon"><img src="info-circle-icon.png" /></div>
                                <input value={client.id} style={{ width: "250px" }} className="inputItem" />
                            </div>
                        </S.ClientInfoBox>
                        <S.ClientInfoBox>
                            <span className="fieldName">EMAIL</span>
                            <div className="valueBox">
                                <div className="icon"><img src="info-circle-icon.png" /></div>
                                <input value={client.email} style={{ width: "250px" }} className="inputItem" />
                            </div>
                        </S.ClientInfoBox>
                        <S.ClientInfoBox>
                            <span className="fieldName">PROFISSÃO</span>
                            <div className="valueBox">
                                <div className="icon"><img src="info-circle-icon.png" /></div>
                                <input value={client.profession} style={{ width: "250px" }} className="inputItem" />
                            </div>
                        </S.ClientInfoBox>
                    </S.PrimaryInfo>


                    <S.SecondaryInfo>
                        <S.ClientInfoBox>
                            <span className="fieldName">Rua</span>
                            <div className="valueBox">
                                {editStreet ? (
                                    <>
                                        <div onClick={() => setEditStreet(false)} className="icon"><img src="cancel-icon.png" /></div>
                                        <div onClick={handleSaveAddress} className="icon"><img src="save-icon.png" /></div>
                                    </>
                                ) : (
                                    <>
                                        <div onClick={() => setEditStreet(true)} className="icon"><img src="edit-icon.png" /></div>
                                    </>
                                )}
                                <input readOnly={!editStreet} className={`power-${editStreet ? "on" : "off"} inputItem`} onChange={(e) => setEditedStreet(e.target.value)} value={editedStreet} style={{ width: "350px" }} />
                            </div>
                        </S.ClientInfoBox>
                        <S.ClientInfoBox>
                            <span className="fieldName">N</span>
                            <div className="valueBox">
                                {editStreetNumber ? (
                                    <>
                                        <div onClick={() => setEditStreetNumber(false)} className="icon"><img src="cancel-icon.png" /></div>
                                        <div onClick={handleSaveAddress} className="icon"><img src="save-icon.png" /></div>
                                    </>
                                ) : (
                                    <>
                                        <div onClick={() => setEditStreetNumber(true)} className="icon"><img src="edit-icon.png" /></div>
                                    </>
                                )}
                                <input readOnly={!editStreetNumber} className={`power-${editStreetNumber ? "on" : "off"} inputItem`} onChange={(e) => setEditedStreetNumber(e.target.value)} value={editedStreetNumber} style={{ width: "150px" }} />
                            </div>
                        </S.ClientInfoBox>
                        <S.ClientInfoBox>
                            <span className="fieldName">BAIRRO</span>
                            <div className="valueBox">
                                {editNeighborhood ? (
                                    <>
                                        <div onClick={() => setEditNeighborhood(false)} className="icon"><img src="cancel-icon.png" /></div>
                                        <div onClick={handleSaveAddress} className="icon"><img src="save-icon.png" /></div>
                                    </>
                                ) : (
                                    <>
                                        <div onClick={() => setEditNeighborhood(true)} className="icon"><img src="edit-icon.png" /></div>
                                    </>
                                )}
                                <input readOnly={!editNeighborhood} className={`power-${editNeighborhood ? "on" : "off"} inputItem`} onChange={(e) => setEditedNeighborhood(e.target.value)} value={editedNeighborhood} style={{ width: "250px" }} />
                            </div>
                        </S.ClientInfoBox>
                        <S.ClientInfoBox>
                            <span className="fieldName">CIDADE</span>
                            <div className="valueBox">
                                {editCity ? (
                                    <>
                                        <div onClick={() => setEditCity(false)} className="icon"><img src="cancel-icon.png" /></div>
                                        <div onClick={handleSaveAddress} className="icon"><img src="save-icon.png" /></div>
                                    </>
                                ) : (
                                    <>
                                        <div onClick={() => setEditCity(true)} className="icon"><img src="edit-icon.png" /></div>
                                    </>
                                )}
                                <input readOnly={!editCity} className={`power-${editCity ? "on" : "off"} inputItem`} onChange={(e) => setEditedCity(e.target.value)} value={editedCity} style={{ width: "300px" }} />
                            </div>
                        </S.ClientInfoBox>
                        <S.ClientInfoBox>
                            <span className="fieldName">CEP</span>
                            <div className="valueBox">
                                {editZipcode ? (
                                    <>
                                        <div onClick={() => setEditZipcode(false)} className="icon"><img src="cancel-icon.png" /></div>
                                        <div onClick={handleSaveAddress} className="icon"><img src="save-icon.png" /></div>
                                    </>
                                ) : (
                                    <>
                                        <div onClick={() => setEditZipcode(true)} className="icon"><img src="edit-icon.png" /></div>
                                    </>
                                )}
                                <input readOnly={!editZipcode} className={`power-${editZipcode ? "on" : "off"} inputItem`} onChange={(e) => setEditedZipcode(e.target.value)} value={editedZipcode} style={{ width: "150px" }} />
                            </div>
                        </S.ClientInfoBox>

                        <S.ClientInfoBox>
                            <span className="fieldName">ESTADO</span>
                            <div className="valueBox">
                                {editState ? (
                                    <>
                                        <div onClick={() => setEditState(false)} className="icon"><img src="cancel-icon.png" /></div>
                                        <div onClick={handleSaveAddress} className="icon"><img src="save-icon.png" /></div>
                                    </>
                                ) : (
                                    <>
                                        <div onClick={() => setEditState(true)} className="icon"><img src="edit-icon.png" /></div>
                                    </>
                                )}
                                <input readOnly={!editState} className={`power-${editState ? "on" : "off"} inputItem`} onChange={(e) => setEditedState(e.target.value)} value={editedState} style={{ width: "250px" }} />
                            </div>
                        </S.ClientInfoBox>

                        <S.ClientInfoBox>
                            <span className="fieldName">PAÍS</span>
                            <div className="valueBox">
                                {editCountry ? (
                                    <>
                                        <div onClick={() => setEditCountry(false)} className="icon"><img src="cancel-icon.png" /></div>
                                        <div onClick={handleSaveAddress} className="icon"><img src="save-icon.png" /></div>
                                    </>
                                ) : (
                                    <>
                                        <div onClick={() => setEditCountry(true)} className="icon"><img src="edit-icon.png" /></div>
                                    </>
                                )}
                                <input readOnly={!editCountry} className={`power-${editCountry ? "on" : "off"} inputItem`} onChange={(e) => setEditedCountry(e.target.value)} value={editedCountry} style={{ width: "200px" }} />
                            </div>
                        </S.ClientInfoBox>

                        <S.ClientInfoBox>
                            <span className="fieldName">INDICADOR</span>
                            <div className="valueBox">
                                {editIndicador ? (
                                    <>
                                        <div onClick={() => setEditIndicador(false)} className="icon"><img src="cancel-icon.png" /></div>
                                        <div onClick={handleSaveIndicator} className="icon"><img src="save-icon.png" /></div>
                                    </>
                                ) : (
                                    <>
                                        <div onClick={() => setEditIndicador(true)} className="icon"><img src="edit-icon.png" /></div>
                                    </>
                                )}
                                <input readOnly={!editIndicador} className={`power-${editIndicador ? "on" : "off"} inputItem`} onChange={(e) => setEditedIndicador(e.target.value)} value={editedIndicador} style={{ width: "300px" }} />
                                <button onClick={() => handleSelectSponsor(client.sponsorId || "")}>
                                    <img alt="mais informações" src="./clientInfo-blue-icon.png" />
                                </button>
                            </div>
                        </S.ClientInfoBox>

                        <S.ClientInfoBox>
                            <span className="fieldName">CONSULTOR</span>
                            <div className="valueBox">
                                {editConsultor ? (
                                    <>
                                        <div onClick={() => setEditConsultor(false)} className="icon"><img src="cancel-icon.png" /></div>
                                        <div onClick={handleSaveConsultor} className="icon"><img src="save-icon.png" /></div>
                                    </>
                                ) : (
                                    <>
                                        <div onClick={() => setEditConsultor(true)} className="icon"><img src="edit-icon.png" /></div>
                                    </>
                                )}
                                <input readOnly={!editConsultor} className={`power-${editConsultor ? "on" : "off"} inputItem`} onChange={(e) => setEditedConsultor(e.target.value)} value={editedConsultor} style={{ width: "300px" }} />
                            </div>
                        </S.ClientInfoBox>


                    </S.SecondaryInfo>
                    <S.ClientTools onMouseLeave={() => setHoverOp(false)}>
                        {hoverOp && (
                            <>
                                <S.ClientToolsOptionsBox>
                                    <span className="header">FERRAMENTAS</span>

                                    <div className="options">
                                        <div className="op" onClick={() => setAdicionarSaldo(true)}>Adicionar Saldo</div>
                                        <div className="op" onClick={() => setChangePass(true)}>Alterar Senha</div>
                                        <div className="op" onClick={() => setExcludeAccount(true)}>Cancelar Conta</div>
                                    </div>
                                </S.ClientToolsOptionsBox>
                            </>
                        )}

                        <img onMouseEnter={() => setHoverOp(true)} src="tools-icon.png" />
                    </S.ClientTools>

                    {excludeAccount && (
                        <>
                            <ExcluirCliente selectedClient={client} setExcluirCliente={setExcludeAccount} />
                        </>
                    )}

                    {adicionarSaldo && (
                        <>
                            <AdicionarSaldo selectedClient={client} setAdicionarSaldo={setAdicionarSaldo} />
                        </>
                    )}

                    {changePass && (
                        <>
                            <S.ChangePassBox>
                                <span className="titulo">ALTERAÇÃO DE SENHA</span>

                                <div className="inputBox">
                                    <input onChange={(e) => setPass1(e.target.value)} value={pass1} className="inputPass" type={seePass1 ? "text" : "password"} placeholder="Digite a Nova Senha" />
                                    <span onClick={() => setSeePass1(!seePass1)} className="seePass">{seePass1 ? "Esconder Senha" : "Ver Senha"}</span>
                                </div>

                                <div className="inputBox">
                                    <input onChange={(e) => setPass2(e.target.value)} value={pass2} className="inputPass" type={seePass2 ? "text" : "password"} placeholder="Confirme a Nova Senha" />
                                    <span onClick={() => setSeePass2(!seePass2)} className="seePass">{seePass2 ? "Esconder Senha" : "Ver Senha"}</span>
                                </div>

                                <div className="botoes">
                                    <button onClick={() => setChangePass(false)} className="cancelButton btn">CANCELAR</button>
                                    <button onClick={handleChangePass} className="confirmButton btn">CONFIRMAR</button>
                                </div>
                            </S.ChangePassBox>
                        </>
                    )}
                </S.ClientBox>
            </S.ClientPageContainer>
        </>
    )
}