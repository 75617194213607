import React, { useContext, useState } from "react";
import * as S from "./CadastroConsultorStyle";
import helpers from "../../helpers";
import { AuthContext } from "../../Context/AuthContext";
import { useLoad } from "../../Context/LoadContext";

export default function CadastroConsultor() {
    const { authState, inserirNovoConsultor } = useContext(AuthContext);
    const { startLoading, stopLoading } = useLoad();
    const [name, setName] = useState("");
    const [id, setId] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");


    function validateEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    const handleCreate = async () => {
        if (!name || !id || !email || !phone) {
            alert("Por favor, preencha todos os campos obrigatórios.");
            return;
        }

        if (!validateEmail(email)) {
            alert("Email Inválido!");
            return;
        }

        startLoading();

        const response = await helpers.cadastroConsultor({
            name,
            id,
            email,
            phone
        }, authState.token);

        if (response != null) {
            alert("Consultor criado com sucesso!");
            inserirNovoConsultor(response);
            setName("");
            setId("");
            setEmail("");
            setPhone("");
            stopLoading();
        } else {
            alert("Erro ao criar o Consultor.");
            stopLoading();
        }
    };


    return (
        <S.CadastroContainer>
            <S.Title>CADASTRO DE CONSULTOR</S.Title>

            <S.ConteudoCadastro>
                <S.Informacao>
                    <input name="name" placeholder="Nome" value={name} onChange={(e) => setName(e.target.value)} />
                </S.Informacao>
                <S.Informacao>
                    <input name="id" placeholder="CPF/CNPJ/ID" value={id} onChange={(e) => setId(e.target.value)} />
                </S.Informacao>
                <S.Informacao>
                    <input type="email" name="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </S.Informacao>
                <S.Informacao>
                    <input name="phone" placeholder="Telefone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                </S.Informacao>

                <S.ButtonConfirmar onClick={handleCreate}>
                    CRIAR CONSULTOR
                </S.ButtonConfirmar>
            </S.ConteudoCadastro>
        </S.CadastroContainer>
    );
}
