import React, { useContext, useEffect, useState } from "react";
import * as S from "./NoticiasStyle";
import helpers from "../../helpers";
import { useLoad } from "../../Context/LoadContext";
import { AuthContext } from "../../Context/AuthContext";

export default function Noticias() {
    const { news, inserirNovaNoticia, excluirNoticia } = useContext(AuthContext);
    const [newsArray, setNewsArray] = useState([]);
    const [creationModal, setCreationModal] = useState(false);
    const [titulo, setTitulo] = useState("");
    const [tipoMidia, setTipoMidia] = useState(null);
    const [texto, setTexto] = useState("");
    const [urlMidia, setUrlMidia] = useState("");
    const [urlDestino, setUrlDestino] = useState("");
    const { startLoading, stopLoadingDelay } = useLoad();
    const [excluirModal, setExcluirModal] = useState(null);

    useEffect(() => {
        setNewsArray(news);
    }, [news, inserirNovaNoticia, excluirNoticia]);

    const handleTestarUrlMidea = () => {
        if (urlMidia && urlMidia.trim() != "") {
            window.open(urlMidia, "_blank");
        } else {
            alert("Informe a URL.");
        }
    }

    const handleTestarUrlDestino = () => {
        if (urlDestino && urlDestino.trim() != "") {
            window.open(urlDestino, "_blank");
        } else {
            alert("Informe a URL.");
        }
    }

    const handleCreate = async () => {
        if (titulo.trim() === "")
            return alert("Informe o título da notícia.");
        if (texto.trim() === "")
            return alert("Informe o texto da notícia.");
        if (urlMidia.trim() === "")
            return alert("Informe a url da midia.");
        if (urlDestino.trim() === "")
            return alert("Informe a url de destino.");

        startLoading()
        const res = await helpers.criarNoticia({
            title: titulo,
            text: texto,
            urlDestin: urlDestino,
            urlMidea: urlMidia
        })

        if (res) {
            alert("Notícia Criada com Sucesso! 👍🏻");
            inserirNovaNoticia(res);
            reset();
        } else {
            alert("Eoo ao Criar Notícia! 👎🏻");
        }
        stopLoadingDelay();
    }

    const reset = () => {
        setTitulo("");
        setTipoMidia(null);
        setTexto("");
        setUrlMidia("");
        setCreationModal(false);
    }

    const handleDeleteNews = async () => {
        if (excluirModal) {
            startLoading()

            const res = await helpers.excluirNoticia(excluirModal);

            if (res) {
                alert("Notícia Excluida com Sucesso! 👍🏻");
                excluirNoticia(excluirModal);
                setExcluirModal(null);
            } else {
                alert("Eoo ao Excluir Notícia! 👎🏻");
            }
            stopLoadingDelay();
        }
    }

    return (
        <>
            <S.NoticiasContainer>
                <S.Title>Portal de Notícias</S.Title>

                <S.CriarUma>
                    <button onClick={() => setCreationModal(true)}>Criar Notícia</button>
                </S.CriarUma>

                <S.NoticiasCriadas>

                    {excluirModal && (
                        <>
                            <div className="certezinha">
                                <div className="certezinha-box">
                                    <span className="confirmMessage">Certeza que deseja excluir a notícia #{excluirModal}</span>
                                    <div className="botoes">
                                        <button onClick={() => setExcluirModal(null)} className="cancelar">Não, Cancelar</button>
                                        <button onClick={handleDeleteNews} className="excluir">Sim, Excluir</button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    <span className="titulo">Notícias Existentes</span>
                    <div className="colunas">
                        <span className="coluna">Id</span>
                        <span className="coluna">Título</span>
                        <span className="coluna">Texto</span>
                        <span className="coluna">Data</span>
                        <span className="coluna">Url</span>
                        <span className="coluna">Url Destino</span>
                        <span className="coluna">---</span>
                    </div>
                    <div className="noticiasLinhas">
                        {newsArray && newsArray.map((n, index) => (
                            <>
                                <div className="noticia">
                                    <span className="item">{n.id}</span>
                                    <span className="item">{n.title}</span>
                                    <span className="item">{n.text}</span>
                                    <span className="item">{helpers.formatDate(n.dateCreated)}</span>
                                    <span className="item">{n.urlMidea}</span>
                                    <span className="item">{n.urlDestin}</span>
                                    <div className="item"><button onClick={() => setExcluirModal(n.id)} className="excluir">Excluir</button></div>
                                </div>
                            </>
                        ))}
                    </div>
                </S.NoticiasCriadas>
            </S.NoticiasContainer>

            {creationModal && (
                <>
                    <S.ModalCriacao>
                        <S.ModalCriacaoContent>
                            <span className="titulo">Crie uma notícia</span>
                            <span className="fechar" onClick={() => setCreationModal(false)}>Cancelar</span>

                            <S.InformeDados>
                                <S.TituloETipo>
                                    <div className="tituloNoticia">
                                        <span className="label">Título</span>
                                        <textarea value={titulo} onChange={(e) => setTitulo(e.target.value)} className="tituloText" />
                                    </div>

                                </S.TituloETipo>

                                <S.Texto>
                                    <span className="label">Texto da Notícia</span>
                                    <textarea onChange={(e) => setTexto(e.target.value)} value={texto} className="textArea" />
                                </S.Texto>

                                <S.Url>
                                    <span className="label">URL Mídia</span>
                                    <div className="urlContent">
                                        <input onChange={(e) => setUrlMidia(e.target.value)} value={urlMidia} className="urlText" placeholder="Digite ou Cole a URL aqui" />
                                        <button onClick={handleTestarUrlMidea} className="puxarUrl">Testar URL</button>
                                    </div>
                                </S.Url>

                                <S.Url style={{marginTop: -40}}>
                                    <span className="label">URL Destino</span>
                                    <div className="urlContent">
                                        <input onChange={(e) => setUrlDestino(e.target.value)} value={urlDestino} className="urlText" placeholder="Digite ou Cole a URL aqui" />
                                        <button onClick={handleTestarUrlDestino} className="puxarUrl">Testar URL</button>
                                    </div>
                                </S.Url>
                            </S.InformeDados>
                            <button onClick={handleCreate} className="criar">Criar</button>
                        </S.ModalCriacaoContent>
                    </S.ModalCriacao>
                </>
            )}
        </>
    )
}