import React, { useEffect, useState, useContext } from "react";
import * as S from "./ConsultoresStyle";
import TabelaGeral from "../TabelaGeral/TabelaGeral";
import { AuthContext } from "../../Context/AuthContext";
import helpers from "../../helpers";
import { useLoad } from "../../Context/LoadContext";


export default function Consultores() {
    const { consultores } = useContext(AuthContext);
    const [searchedConsultores, setSearchedConsultores] = useState({});
    const {startLoading, stopLoading} = useLoad();

    useEffect(() => {
        startLoading()
        setTimeout(stopLoading, 1200);
    }, [consultores])

    const columns = [
        { name: "Nome", value: "name" },
        { name: "CPF/CNPJ", value: "id" },
        { name: "Email", value: "email" },
        { name: "Telefone", value: "phone" },
        { name: "Data Cadastro", value: "dateCreated", formatFunction: helpers.formatDate },
        { name: "Clientes", value: "clientsQtt" },
        { name: "Status", value: "status", formatFunction: helpers.handleStatusClient },
    ];

    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [stateFilter, setStateFilter] = useState("Todos");
    const [sortOrder, setSortOrder] = useState("Crescente");
    const itemsPerPage = 10;

    useEffect(() => {
        if (consultores && consultores.length > 0) {
            filterAndSortData();
        }
    }, [consultores, searchTerm, stateFilter, sortOrder]);

    const filterAndSortData = () => {
        let filteredClients = consultores.map(client => {
            return {
                ...client
            };
        });

        if (searchTerm) {
            filteredClients = filteredClients.filter(client =>
                Object.values(client).some(value =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        }

        const sortedClients = filteredClients.sort((a, b) => {
            if (sortOrder === "Crescente") {
                return sortById(a.id, b.id); // Função de comparação para o ID
            } else {
                return sortById(b.id, a.id); // Reverso para Descrescente
            }
        });
        setSearchedConsultores(sortedClients);
    };

    const sortById = (idA, idB) => {
        const matchA = idA.match(/([A-Za-z]+)(\d+)/);
        const matchB = idB.match(/([A-Za-z]+)(\d+)/);

        const prefixA = matchA ? matchA[1] : idA; 
        const numberA = matchA ? parseInt(matchA[2], 10) : 0; 
        const prefixB = matchB ? matchB[1] : idB; 
        const numberB = matchB ? parseInt(matchB[2], 10) : 0; 

        if (prefixA < prefixB) return -1;
        if (prefixA > prefixB) return 1;

        return numberA - numberB;
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Object.values(searchedConsultores).slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(searchedConsultores.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };
    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        setCurrentPage(1);
    };

    // if(!consultores || consultores.length == 0)
    //     return null;


    return (
        <>
            <S.ClientsContainer>
                <S.Title>PÁGINA DOS CONSULTORES</S.Title>

                <S.Pesquisa>
                    <div className="inputBox">
                        <input
                            placeholder="Pesquise"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <img src="search-icon.png" alt="Search Icon" />
                    </div>
                    <S.FiltrosSecondarios>

                        <S.Filtro>
                            <p>Ordenar</p>
                            <S.FiltroSelect value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
                                <option value="Crescente">Crescente</option>
                                <option value="Descrescente">Descrescente</option>
                            </S.FiltroSelect>
                        </S.Filtro>
                    </S.FiltrosSecondarios>
                </S.Pesquisa>

                <S.TabelaDeClientes>
                    <TabelaGeral columns={columns} data={currentItems} type="consultores" setSelectedThing={() => console.log("")} />
                </S.TabelaDeClientes>

                <S.Pagination>
                    <button onClick={handlePrevPage} disabled={currentPage === 1}>Anterior</button>
                    <span>Página {currentPage} de {totalPages}</span>
                    <button onClick={handleNextPage} disabled={currentPage === totalPages}>Próximo</button>
                </S.Pagination>
            </S.ClientsContainer>
        </>
    );
}