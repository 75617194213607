import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

const VideoPlayerContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    border: 2px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
`;

const Controls = styled.div`
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    gap: 20px;
`;

const Button = styled.button`
    color: white;
    background-color: #007bff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;

const VolumeSlider = styled.input`
    cursor: pointer;
`;

const ProgressSlider = styled.input`
    cursor: pointer;
    width: 100%;
`;

const CustomVideoPlayer = ({ url }) => {
    const [playing, setPlaying] = React.useState(false);
    const [volume, setVolume] = React.useState(0.5);
    const [controlsVisible, setControlsVisible] = React.useState(true);
    const [played, setPlayed] = React.useState(0);
    const playerRef = useRef();

    const togglePlayPause = () => {
        setPlaying(!playing);
    };

    const handleVolumeChange = (event) => {
        setVolume(event.target.value);
    };

    const handleProgress = (state) => {
        setPlayed(state.played);
    };

    const handleSeeking = (event) => {
        const seekTo = parseFloat(event.target.value);
        playerRef.current.seekTo(seekTo);
    };

    const toggleControls = () => {
        setControlsVisible(!controlsVisible);
    };

    return (
        <VideoPlayerContainer onMouseEnter={toggleControls} onMouseLeave={toggleControls}>
            <ReactPlayer 
                ref={playerRef}
                url={url}
                playing={playing}
                volume={volume}
                width="100%"
                height="100%"
                controls={false} // Desabilita os controles padrão
                onProgress={handleProgress} // Atualiza o progresso
            />
            {controlsVisible && (
                <Controls>
                    <Button onClick={togglePlayPause}>
                        {playing ? 'Pause' : 'Play'}
                    </Button>
                    <VolumeSlider 
                        type="range" 
                        min="0" 
                        max="1" 
                        step="0.1" 
                        value={volume} 
                        onChange={handleVolumeChange} 
                    />
                    <ProgressSlider 
                        type="range" 
                        min="0" 
                        max="1" 
                        step="0.01" 
                        value={played} 
                        onChange={handleSeeking} 
                    />
                </Controls>
            )}
        </VideoPlayerContainer>
    );
};

export default CustomVideoPlayer;