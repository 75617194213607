import styled from "styled-components";

export const IndicacaoControllerContainer = styled.div`
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 40px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    justify-content: center;
`;

export const Title = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 48px;
    color: white;
`;

export const SelecaoAtual = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .box{
        width: 350px;
        border: 3px solid white;
        box-shadow: 3px 3px 1px rgba(0,0,0,0.6);
        backgrond: rgba(0,0,0,0.6);
        border-radius: 3px;
        filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.4));
        padding: 20px;
        box-sizing: border-box;

        .mensagem{
            margin: 0;
            font-size: 18px;
            color: white;

            .bold{
                font-weight: 800;
                
            }
        }
    }
`;

export const AlterarButton = styled.div`
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 10px;

    .buttonAlterar{
        font-size: 16px;
        color: rgba(220, 220, 255, 1);
        font-weight: 500;
        transition: .3s;
        cursor: pointer;

        &:hover{
            color: rgba(255, 255, 255, 1);
        }
    }

`;

export const CaixaAlteracao = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .box{
        width: max-content;
        border: 3px solid white;
        box-shadow: 3px 3px 1px rgba(0,0,0,0.6);
        background: rgba(0,0,0,0.6);
        border-radius: 3px;
        filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.4));
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .inputBox{
            display: flex;
            flex-direction: column;
            align-items: center;  
            
            .label{
                font-size: 18px;
                color: white;
                text-shadow: 2px 2px 1px rgba(0,0,0,0.6);
            }

            .selectionBox{
                max-width: 400px;
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                justify-content: center;
                margin-top: 10px;

                input{
                    width: 200px;
                    height: 35px;
                    border-radius: 3px;
                    border: 3px solid white;
                    font-size: 16px;
                    color: white;
                    font-weight: 600;
                    text-align: center;
                    box-sizing: border-box;
                    background: rgba(0,0,0,0.6);
                    box-shadow: 2px 2px 1px rgba(0,0,0,0.8);
                }

                .ouButton{
                    margin-top: 10px;
                    color: rgba(0, 180, 255, 1);
                }

                .excludeButton{
                    margin-top: 10px;
                    color: rgba(255, 80, 0, 1);
                }
            }
        }

        .buttonSave{
            width: 300px;
            height: 40px;
            cursor: pointer;
            color: white;
            text-shadow: 2px 2px 4px rgba(0,0,0,0.6);
            border-radius: 6px;
            background: linear-gradient(to right, rgba(0, 255, 0, 1), rgba(100, 255, 0, 1));
            transition: .3s;
            font-size: 18px;

            &:hover{
                color: black;
                text-shadow: 1px 1px 1px rgba(0,0,0,0);
            }
        }
    }
`;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;
