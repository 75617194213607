import React, { useState, useEffect, useContext } from "react";
import * as S from "./IndicacaoStyle";
import { AuthContext } from "../../../Context/AuthContext";
import helpers from "../../../helpers";
import { useLoad } from "../../../Context/LoadContext";


export default function IndicacaoController() {
    const { authState, systemConfigs, atualizarSystemConfigs } = useContext(AuthContext);
    const { startLoading, stopLoadingDelay } = useLoad();
    const [edit, setEdit] = useState(false);
    const [percentToIndicator, setPercentToIndicator] = useState("");
    const [percentDatabase, setPercentDatabase] = useState(null);

    useEffect(() => {
        if(systemConfigs){
            var obj = systemConfigs.filter(s => s.name === "indication")[0];
            if(obj){
                setPercentDatabase(obj.value)
            }
        }
    }, [systemConfigs, atualizarSystemConfigs])

    const handleSave = async () => {
        startLoading();
        if (parseFloat(percentToIndicator) > 0 && !percentDatabase) {
            var response = await helpers.salvarIndicacao({name: "indication", value: percentToIndicator}, authState.token);
            if (response) alert("Indicação Salva Com Sucesso.");
            else alert("Erro Ao Salvar Indicação.");
        }else if(parseFloat(percentToIndicator) > 0 && percentDatabase){
            var response = await helpers.editarIndicacao(percentToIndicator, authState.token);
            if (response) alert("Nova Indicação Salva Com Sucesso.");
            else alert("Erro Ao Salvar Indicação.");
        }else if(parseFloat(percentToIndicator) === 0){
            var response = await helpers.excluirIndicacao(authState.token);
            if (response) alert("Indicação Desativada Com Sucesso. Atualize a pagina");
            else alert("Erro Ao Desativar Indicação.");
        }
        await atualizarSystemConfigs();
        setEdit(false);
        setPercentToIndicator(0);
        stopLoadingDelay();
    }

    return (
        <>
            <S.IndicacaoControllerContainer>
                <S.Title>Controlador de Indicação</S.Title>

                {!edit && (
                    <>
                        <S.SelecaoAtual>
                            <div className="box">
                                <p className="mensagem">
                                    INDICAÇÃO {percentDatabase ? "ATIVA DE" : "DESATIVADA"}
                                    <span className="bold"> {percentDatabase ? percentDatabase + "%" : ""}</span>
                                </p>
                            </div>
                        </S.SelecaoAtual>
                        <S.AlterarButton onClick={() => setEdit(true)}><span className="buttonAlterar">Alterar</span></S.AlterarButton>
                    </>
                )}

                {edit && (<>
                    <S.AlterarButton onClick={() => setEdit(false)}><span className="buttonAlterar">Cancelar</span></S.AlterarButton>

                    <S.CaixaAlteracao>
                        <div className="box">
                            <div className="inputBox">
                                <span className="label">QUANTO SERÁ PAGO PELA INDICAÇÃO?</span>
                                <div className="selectionBox">
                                    <input
                                        value={percentToIndicator}
                                        onChange={(e) => setPercentToIndicator(e.target.value)}
                                        placeholder="Digite a porcentagem"
                                    />
                                </div>
                            </div>
                            <button onClick={handleSave} className="buttonSave">SALVAR</button>
                        </div>
                    </S.CaixaAlteracao>
                </>)}
            </S.IndicacaoControllerContainer>
        </>
    )
}