import React, { useContext, useState } from "react";
import * as S from "./CadastroStyle";
import helpers from "../../helpers";
import { AuthContext } from "../../Context/AuthContext";
import { useLoad } from "../../Context/LoadContext";

export default function Cadastro() {
    const { clients, authState, inserirNovoCliente } = useContext(AuthContext);
    const [showIndicadores, setShowIndicadores] = useState(false);
    const [sponsorSelected, setSponsorSelected] = useState(null);
    const [filteredSponsors, setFilteredSponsors] = useState([]);
    const { startLoading, stopLoading } = useLoad();

    const [cliente, setCliente] = useState({
        name: "",
        id: "",
        email: "",
        phone: "",
        balance: 0.0,
        platformId: "01",
        profession: "",
        monthlyIncome: "",
        password: "",
        confirmPassword: "",
        sponsorId: "",
        address: {
            street: "",
            number: "",
            neighborhood: "",
            city: "",
            state: "",
            zipcode: ""
        }
    });

    function validateEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    function isSecurePassword(password) {
        const minLength = 6;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);

        return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumber;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name in cliente.address) {
            setCliente(prevState => ({
                ...prevState,
                address: {
                    ...prevState.address,
                    [name]: value // Atualiza o campo específico do endereço
                }
            }));
        } else {
            setCliente(prevState => ({
                ...prevState,
                [name]: value // Atualiza o campo do cliente
            }));
        }
    };

    const handleCreate = async () => {
        if (!cliente.name || !cliente.id || !cliente.email || !cliente.phone || !cliente.address.street ||
            !cliente.address.number || !cliente.address.city || !cliente.address.zipcode ||
            !cliente.password || !cliente.confirmPassword || cliente.password != cliente.confirmPassword || !cliente.address.state) {

            if (cliente.password != cliente.confirmPassword) {
                alert("As senhas não se coincidem.")
            } else alert("Por favor, preencha todos os campos obrigatórios.");
            return;
        }

        if (!validateEmail(cliente.email)) {
            alert("Email Inválido!");
            return;
        }

        if (cliente.password !== cliente.confirmPassword) {
            alert("A senha e a confirmação da senha não coincidem.");
            return;
        }

        if (!isSecurePassword(cliente.password)) {
            alert("A senha precisa ter 6 caracteres, pelo menos um caractere meiúsculo e um número");
            return;
        }

        if (cliente.sponsorId.trim() === "") {
            setCliente({
                ...cliente,
                sponsorId: ""
            });
        }

        startLoading();

        const response = await helpers.cadastro(cliente, authState.token);
        if (response != null) {
            alert("Cliente criado com sucesso!");
            inserirNovoCliente(response);
            setCliente({
                name: "",
                id: "",
                email: "",
                phone: "",
                balance: 0.0,
                platformId: "01",
                zipcode: "",
                profession: "",
                monthlyIncome: "",
                password: "",
                confirmPassword: "",
                sponsorId: "",
                address: {
                    street: "",
                    number: "",
                    neighborhood: "",
                    city: "",
                    state: "",
                    zipcode: ""
                }
            });
            stopLoading();
        } else {
            alert("Erro ao criar o Cliente.");
            stopLoading();
        }
    };


    return (
        <S.CadastroContainer>
            <S.Title>CADASTRO DE CLIENTE</S.Title>

            <S.ConteudoCadastro>
                <S.Informacao>
                    <input name="name" placeholder="Nome" value={cliente.name} onChange={handleChange} />
                </S.Informacao>
                <S.Informacao>
                    <input name="id" placeholder="CPF/CNPJ" value={cliente.id} onChange={handleChange} />
                </S.Informacao>
                <S.Informacao>
                    <input type="email" name="email" placeholder="Email" value={cliente.email} onChange={handleChange} />
                </S.Informacao>
                <S.Informacao>
                    <input name="phone" placeholder="Telefone" value={cliente.phone} onChange={handleChange} />
                </S.Informacao>
                <S.Informacao>
                    <input name="street" placeholder="Rua" value={cliente.address.street} onChange={handleChange} />
                </S.Informacao>
                <S.Informacao>
                    <input name="number" placeholder="N" value={cliente.address.number} onChange={handleChange} />
                </S.Informacao>
                <S.Informacao>
                    <input name="neighborhood" placeholder="Bairro" value={cliente.address.neighborhood} onChange={handleChange} />
                </S.Informacao>
                <S.Informacao>
                    <input name="city" placeholder="Cidade" value={cliente.address.city} onChange={handleChange} />
                </S.Informacao>
                <S.Informacao>
                    <input name="state" placeholder="Estado" value={cliente.address.state} onChange={handleChange} />
                </S.Informacao>
                <S.Informacao>
                    <input name="zipcode" placeholder="CEP" value={cliente.address.zipcode} onChange={handleChange} />
                </S.Informacao>
                <S.Informacao>
                    <input
                        name="sponsorId"
                        placeholder="Indicador"
                        value={cliente.sponsorId || ""}
                        onChange={handleChange}
                        type="text"
                    />
                </S.Informacao>


                <S.CadastroSenha>
                    <input type="password" name="password" placeholder="Crie uma senha" value={cliente.password} onChange={handleChange} />
                    <input type="password" placeholder="Confirme a senha" onChange={(e) => setCliente({ ...cliente, confirmPassword: e.target.value })} />
                </S.CadastroSenha>

                <S.ButtonConfirmar onClick={handleCreate}>
                    CRIAR CLIENTE
                </S.ButtonConfirmar>
            </S.ConteudoCadastro>
        </S.CadastroContainer>
    );
}
