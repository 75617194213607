import styled from "styled-components";

export const NoticiasContainer = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const Title = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 48px;
    color: rgba(255, 255, 255,1);
    font-weight: 600;
`;

export const CriarUma = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: end;

    button{
        width: 300px;
        border-radius: 3px;
        box-sizing: border-box;
        height: 40px;
        color: white;
        font-size: 16px;
        cursor: pointer;
        font-weight: 500;
        background: linear-gradient(-45deg, rgba(43, 23, 255, 1), rgba(43, 23, 180, 1))
    }
`

export const NoticiasCriadas = styled.div`
    width: 100%;
    height: 500px;
    display: grid;
    grid-template-rows: 1fr 2fr 40fr;
    overflow: auto;
    box-sizing: border-box;
    padding: 30px;
    background: rgba(0,0,0,0.8);
    margin-top: 40px;
    border-radius: 6px;
    border: 1px solid rgba(50, 50, 50, 1);
    gap: 10px;
    position: relative;

    .titulo{
        width: 100%;
        font-size: 28px;
        color: white;
        text-align: start;
        border-bottom: 3px solid rgba(50, 50, 50, 1);
    }

    .colunas{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        border-bottom: 0px solid white;

        .coluna{
            width: 100%;
            font-size: 18px;
            height: 30px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            justify-content: center;
            color: white;
            border: 1px solid white;
        }
    }

    .noticiasLinhas{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: auto;

        .noticia{
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            transition: .3s;
            cursor: pointer;

            &:hover{
                background: rgba(230, 230, 230, 1);
                .item{
                    color: black;
                }
            }

            .item {
                width: 100%;
                font-size: 18px;
                overflow: hidden;          
                height: 30px;              
                display: flex;
                align-items: center;
                box-sizing: border-box;
                justify-content: center;
                padding: 10px;
                color: white;
                border: 1px solid white;
                white-space: nowrap;       
                text-overflow: ellipsis;  

                .excluir{
                    width: 100%;
                    box-sizing: border-box;
                    background: red;
                    border: 0;
                    color: white;
                    text-shadow: 1px 1px 1px black;
                    font-size: 13px;
                    cursor: pointer;
                    transition: .3s;

                    &:hover{
                        transform: scale(0.97);
                    }
                }
            }
        }
    }

    .certezinha{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.8);
        width: 100%;
        height: 100%;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        .certezinha-box{
            box-sizing: border-box;
            padding: 25px;
            border: 1px solid white;
            border-radius: 6px;
            background: rgba(0, 0, 0, 1);
            gap: 10px;
            display: flex;
            flex-direction: column;

            .confirmMessage{
                font-size: 22px;
                font-weight: 800;
                color: white;
            }

            .botoes{
                display: flex;
                width: 100%;
                justify-content: center;
                gap: 10px;

                button{
                    width: 100%;
                    height: 40px;
                    cursor: pointer;
                    transition: .3s;
                    text-shadow: 1px 1px 1px black;
                    font-size: 18px;
                    color: white;
                    &:hover{
                        transform: scale(0.97);
                    }
                }

                .cancelar{
                    background: red;
                }

                .excluir{
                    background: green;
                }
            }
        }
    }
`

export const ModalCriacao = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.8);
    box-sizing: border-box;
`

export const ModalCriacaoContent = styled.div`
    width: 80%;
    height: max-content;
    border-radius: 6px;
    background: rgba(20, 48, 80, 1);
    box-sizing: border-box;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    position: relative;

    .titulo{
        font-size: 32px;
        color: white;
        font-weight: 800;
        width: 100%;
        display: flex;
        justify-content: start;
        text-decoration: underline;
    }

    .fechar{
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 22px;
        color: red;
        font-weight: 600;
        cursor: pointer;
        transition: .3s;

        &:hover{
            color: rgba(200, 0, 0, 1);
        }
    }

    .criar{
        height: 40px;
        width: 100%;
        font-size: 18px;
        cursor: pointer;
        font-weight: 600;
        background: linear-gradient(-45deg, rgba(80, 200, 0, 1), rgba(120, 255, 0, 1), rgba(80, 200, 0, 1))
    }

`

export const InformeDados = styled.div`
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-rows: 2fr 4fr 2fr 1fr;
    // margin-top: 40px;
    box-sizing: border-box;
`

export const TituloETipo = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: flex;
    // gap: 20px;

    .tituloNoticia{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 5px;

        .label{
            font-size: 22px;
            color: rgba(255, 255, 255, 0.7);
            font-weight: 500;
        }

        .tituloText{
            width: 100%;
            height: 100px;
            font-size: 22px;
            box-sizing: border-box;
            padding: 10px 5px;
            font-weight: 800;
        }
    }

`

export const Texto = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: start;
    // margin-top: 20px;

    .label{
        font-size: 22px;
        color: rgba(255, 255, 255, 0.7);
        font-weight: 500;
    }

    .textArea{
        width: 100%;
        height: 100%;
        font-size: 22px;
        box-sizing: border-box;
        padding: 10px 5px;
        font-weight: 800;
    }
`

export const Url = styled.div`
    width: 100%;
    // height: 100px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: start;

    .label{
        font-size: 22px;
        color: rgba(255, 255, 255, 0.7);
        font-weight: 500;
    }

    .urlContent{
        width: 100%;
        display: grid;
        grid-template-columns: 5fr 1fr;
        gap: 5px;

        .urlText{
            height: 40px;
        }

        .puxarUrl{
            cursor: pointer;
            color: white;
            font-size: 18px;
            background: linear-gradient(-45deg, rgba(43, 23, 255, 1), rgba(43, 23, 180, 1))
        }
    }

`

// export const nome = styled.div``

// export const nome = styled.div``


