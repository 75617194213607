import styled from "styled-components"

export const ClientPageContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.9);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    // padding: 20px;
    z-index: 9;
`;

export const ClientBox = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: linear-gradient(-45deg, #003566, #1e96fc);
    border-radius: 6px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    padding: 30px 0;
    

    .exit{
        width: 200px;
        height: 30px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 14px;
        border: 0;
        cursor: pointer;
        transition: .3s;
        text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
        box-shadow: 2px 2px 1px rgba(0,0,0,0.6);
        position: absolute;
        top: 20px;
        left: 20px;
        background: linear-gradient(to right, rgba(255, 0, 0, 1), rgba(255, 100, 0, 1));
        &:hover{
            transform: scale(0.95);
            box-shadow: 3px 4px 3px rgba(0,0,0,0.6);
        }
    }
`;

export const ProfilePhoto = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 80px 0 0 0;
    box-sizing: border-box;

    .roundedBox{
        width: 300px;
        height: 300px;
        background: rgba(0,0,0,0.4);
        border-radius: 50%;
        border: 3px solid white;
        box-shadow: 2px 3px 1px rgba(0,0,0,0.6);
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            width: 70%;
        }
    }

    .clientName{
        width: 100%;
        display: flex;
        text-align: center;
        margin-top: 20px;
        justify-content: center;
        font-size: 18px;
        color: white;
        text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
    }
`;

export const PrimaryInfo = styled.div`
    width: 80%;
    display: flex;
    gap: 20px;
    padding: 20px 30px 30px 0;
    flex-wrap: wrap;
    border-bottom: 3px solid white;
`;

export const SectionTitle = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    padding: 20px 30px 30px 0;
    box-sizing: border-box;
    font-size: 18px;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
`;

export const SecondaryInfo = styled.div`
    width: 80%;
    display: flex;
    gap: 20px;
    padding: 20px 30px 30px 0;
    flex-wrap: wrap;
`;

export const ClientInfoBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-sizing: border-box;

    .fieldName{
        width: 100%;
        border-bottom: 2px solid white;
        color: white;
    }

    .valueBox{
        display: flex;
        gap: 10px;
        box-sizing: border-box;

        .icon{
            width: 40px;
            height: 40px;
            border-radius: 3px;
            border: 2px solid white;
            background: rgba(0,0,0,0.6);
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                width: 90%;
                cursor: pointer;
                transition: .3s;

                &:hover{
                    opacity: 0.6;
                }
            }
        }

        .inputItem{
            // width: 250px;
            box-sizing: border-box;
            padding-left: 20px;
            border: 0;
            border-radius: 3px;
            font-size: 17px;
            color: rgba(0,0,0,0.8);
        }

        button{
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: radial-gradient(rgba(0,0,0,1), rgba(50, 50, 50, 1));
            border: 2px solid white;
            box-sizing: border-box;
            padding: 0;
            cursor: pointer;
            transition: .3s;

            &:hover{
                transform: scale(0.98);
            }
            img{
                width: 80%;
            }
        }

        .power-on{
            background-color: rgba(0,0,0,0.4);
            color: white;
        }
    }   

`;

export const LastPartBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    .roundedBox{
        width: 300px;
        height: 300px;
        border-radius: 6px;
        border: 3px solid white;
        background: rgba(0,0,0,0.6);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .brancoForte{
            font-size: 22px;
            font-weight: 600;
            color: white;
        }
    }
`;

export const ClientTools = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
    box-sizing: border-box;

    img{
        width: 60px;
        position: absolute;
        top: 0px;
        right: 20px;
        cursor: pointer;
    }
`;

export const ClientToolsOptionsBox = styled.div`
    width: 300px;
    border-radius: 6px;
    background: linear-gradient(-45deg, rgba(30, 30, 30, 1), rgba(50, 50, 50, 1), rgba(30, 30, 30, 1));
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header{
        color: white;
        font-size: 18px;
        text-align: center;
        font-weight: 600;
        width: 100%;
        border-bottom: 3px solid rgba(0, 200, 255, 1);
    }

    .options{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    
        .op{
            width: 100%;
            height: 40px;
            transition: .3s;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            border: 3px solid rgba(0, 200, 255, 0);
            cursor: pointer;

            &:hover{
                transform: scale(1.05);
                border: 3px solid rgba(0, 200, 255, 1);
            }
        }
    }
`;

export const ChangePassBox = styled.div`
    z-index: 9;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: linear-gradient(-45deg, rgba(30, 30, 30, 1), rgba(50, 50, 50, 1), rgba(30, 30, 30, 1));
    border-radius: 6px;
    box-sizing: border-box;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .titulo{
        color: rgba(0, 200, 255, 1);
        font-size: 18px;
        text-align: center;
        font-weight: 600;
        width: 100%;
        border-bottom: 3px solid rgba(0, 200, 255, 1);
        margin-bottom: 20px;
    }

    .inputBox{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 10px;
        gap: 4px;

        input{
            width: 100%;
            height: 30px;
            border-radius: 3px;
            border: 0;
            box-sizing: border-box;
            padding-left: 20px;
        }

        .seePass{
            width: 100%;
            display: flex;
            justify-content: end;
            color: white;
            font-size: 12px;
            font-weight: 600;
            cursor: pointer;
        }
    }

    .botoes{
        display: flex;
        gap: 10px;
        width: 100%;

        .btn{
            width: 100%;
            height: 35px;
            border-radius: 3px;
            border: 0;
            font-size: 16px;
            cursor: pointer;
            transition: .3s;
            font-weight: 600;

            &:hover{    
                transform: scale(0.97);
            }
        }

        .confirmButton{
            background: linear-gradient(to right, rgba(0, 200, 0, 1), rgba(100, 255, 0, 1));
            color: black;
        }

        .cancelButton{
            background: linear-gradient(to right, rgba(180, 0, 0, 1), rgba(255, 0, 0, 1));
            color: white;
        }
    }
`;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;


