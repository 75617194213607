import React, { useState, useEffect, useContext } from "react";
import * as S from "./SaquesStyle";
import { AuthContext } from "../../../Context/AuthContext";
import helpers from "../../../helpers";
import { useLoad } from "../../../Context/LoadContext";

export default function SaquesController() {
    const { systemConfigs, atualizarSystemConfigs, authState } = useContext(AuthContext);
    const [edit, setEdit] = useState(false);
    const [monthsAdded, setMonthsAdded] = useState([]);
    const [daysAdded, setDaysAdded] = useState([]);
    const [monthsAddedDatabase, setMonthsAddedDatabase] = useState("");
    const [daysAddedDatabase, setDaysAddedDatabase] = useState("");
    const {startLoading, stopLoadingDelay} = useLoad();

    const handleSelectMonth = (monthNumber) => {
        if (!monthsAdded.includes(monthNumber))
            setMonthsAdded([...monthsAdded, monthNumber]);
        else setMonthsAdded(monthsAdded.filter(mon => mon != monthNumber));
    }

    const returnBoxClass = (number) => {
        return monthsAdded.includes(number) ? "selected" : "";
    }

    const handleSelectDays = (dayNumber) => {
        if (!daysAdded.includes(dayNumber))
            setDaysAdded([...daysAdded, dayNumber]);
        else setDaysAdded(daysAdded.filter(day => day != dayNumber));
    }

    const returnBoxClassDays = (number) => {
        return daysAdded.includes(number) ? "selected" : "";
    }

    useEffect(() => {
        if (systemConfigs) {
            var monthsString = systemConfigs.find(s => s.name == "withdrawn_date_months")
            if (monthsString) {
                setMonthsAddedDatabase(monthsString); 
                var monthsArray = monthsString.value.split("-");
                setMonthsAdded(monthsArray);
            }
            var daysString = systemConfigs.find(s => s.name == "withdrawn_date_days")
            if (daysString) {
                var sortedDaysString1 = helpers.sortArray(daysString.value.split("-")).filter(it => it).join(", ")
                console.log(sortedDaysString1)
                setDaysAddedDatabase(sortedDaysString1); 
                var daysArray = daysString.value.split("-");
                setDaysAdded(daysArray);
            }
        }
    }, [atualizarSystemConfigs]);

    const handleSave = async () => {
        let resultMonths = monthsAdded.filter(month => month).join("-");
        let resultDays = daysAdded.filter(day => day).join("-");

        if(resultDays.trim() != "" && resultMonths.trim() != ""){
            startLoading();
            var result1 = await helpers.salvarEdicaoMesesDeSaque(resultMonths, authState.token);
            var result2 = await helpers.salvarEdicaoDiasDeSaque(resultDays, authState.token);

            if(result1 && result2){
                alert("Atualizado com sucesso.");
                await atualizarSystemConfigs();
                setEdit(false);
            }else{
                if(!result1)
                    alert("Erro ao atualizar os meses de saque.");

                if(!result1)
                    alert("Erro ao atualizar os dias de saque.");
                setEdit(false);  
            }

            stopLoadingDelay();
        }
    }

    const handleArrayMonths = () => {
        var stringMeses = monthsAddedDatabase.value || "";

        return helpers.sortArray(stringMeses.split("-"))
            .map(month => helpers.handleMonthNumber(month)) 
            .join(", "); 
    };


    return (
        <>
            <S.SaquesControllerContainer>
                <S.Title>Controlador de Saques</S.Title>

                {!edit && (
                    <>
                        <S.SelecaoAtual>
                            <div className="box">
                                <p className="mensagem">
                                    Saques nos meses
                                    <span className="bold"> {monthsAddedDatabase && handleArrayMonths()}</span>
                                </p>
                                <p className="mensagem">
                                    Todo dia
                                    <span className="bold">  {daysAddedDatabase && daysAddedDatabase}</span>
                                </p>
                            </div>
                        </S.SelecaoAtual>
                        <S.AlterarButton onClick={() => setEdit(true)}><span className="buttonAlterar">Alterar</span></S.AlterarButton>
                    </>
                )}

                {edit && (<>
                    <S.AlterarButton onClick={() => setEdit(false)}><span className="buttonAlterar">Cancelar</span></S.AlterarButton>

                    <S.CaixaAlteracao>
                        <div className="box">
                            <div className="inputBox">
                                <span className="label">O SAQUE SERÁ EM QUAIS MESES DO ANO?</span>
                                <div className="selectionBox">
                                    <div onClick={() => handleSelectMonth("1")} className={`boxMonth ${returnBoxClass("1")}`}>JAN</div>
                                    <div onClick={() => handleSelectMonth("2")} className={`boxMonth ${returnBoxClass("2")}`}>FEV</div>
                                    <div onClick={() => handleSelectMonth("3")} className={`boxMonth ${returnBoxClass("3")}`}>MAR</div>
                                    <div onClick={() => handleSelectMonth("4")} className={`boxMonth ${returnBoxClass("4")}`}>ABR</div>
                                    <div onClick={() => handleSelectMonth("5")} className={`boxMonth ${returnBoxClass("5")}`}>MAI</div>
                                    <div onClick={() => handleSelectMonth("6")} className={`boxMonth ${returnBoxClass("6")}`}>JUN</div>
                                    <div onClick={() => handleSelectMonth("7")} className={`boxMonth ${returnBoxClass("7")}`}>JUL</div>
                                    <div onClick={() => handleSelectMonth("8")} className={`boxMonth ${returnBoxClass("8")}`}>AGO</div>
                                    <div onClick={() => handleSelectMonth("9")} className={`boxMonth ${returnBoxClass("9")}`}>SET</div>
                                    <div onClick={() => handleSelectMonth("10")} className={`boxMonth ${returnBoxClass("10")}`}>OUT</div>
                                    <div onClick={() => handleSelectMonth("11")} className={`boxMonth ${returnBoxClass("11")}`}>NOV</div>
                                    <div onClick={() => handleSelectMonth("12")} className={`boxMonth ${returnBoxClass("12")}`}>DEZ</div>
                                </div>
                            </div>

                            <div className="inputBox">
                                <span className="label">O SAQUE SERÁ EM QUE DIAS DO MÊS?</span>
                                <div className="selectionBox">
                                    <div onClick={() => handleSelectDays("1")} className={`boxMonth ${returnBoxClassDays("1")}`}>01</div>
                                    <div onClick={() => handleSelectDays("2")} className={`boxMonth ${returnBoxClassDays("2")}`}>02</div>
                                    <div onClick={() => handleSelectDays("3")} className={`boxMonth ${returnBoxClassDays("3")}`}>03</div>
                                    <div onClick={() => handleSelectDays("4")} className={`boxMonth ${returnBoxClassDays("4")}`}>04</div>
                                    <div onClick={() => handleSelectDays("5")} className={`boxMonth ${returnBoxClassDays("5")}`}>05</div>
                                    <div onClick={() => handleSelectDays("6")} className={`boxMonth ${returnBoxClassDays("6")}`}>06</div>
                                    <div onClick={() => handleSelectDays("7")} className={`boxMonth ${returnBoxClassDays("7")}`}>07</div>
                                    <div onClick={() => handleSelectDays("8")} className={`boxMonth ${returnBoxClassDays("8")}`}>08</div>
                                    <div onClick={() => handleSelectDays("9")} className={`boxMonth ${returnBoxClassDays("9")}`}>09</div>
                                    <div onClick={() => handleSelectDays("10")} className={`boxMonth ${returnBoxClassDays("10")}`}>10</div>
                                    <div onClick={() => handleSelectDays("11")} className={`boxMonth ${returnBoxClassDays("11")}`}>11</div>
                                    <div onClick={() => handleSelectDays("12")} className={`boxMonth ${returnBoxClassDays("12")}`}>12</div>
                                    <div onClick={() => handleSelectDays("13")} className={`boxMonth ${returnBoxClassDays("13")}`}>13</div>
                                    <div onClick={() => handleSelectDays("14")} className={`boxMonth ${returnBoxClassDays("14")}`}>14</div>
                                    <div onClick={() => handleSelectDays("15")} className={`boxMonth ${returnBoxClassDays("15")}`}>15</div>
                                    <div onClick={() => handleSelectDays("16")} className={`boxMonth ${returnBoxClassDays("16")}`}>16</div>
                                    <div onClick={() => handleSelectDays("17")} className={`boxMonth ${returnBoxClassDays("17")}`}>17</div>
                                    <div onClick={() => handleSelectDays("18")} className={`boxMonth ${returnBoxClassDays("18")}`}>18</div>
                                    <div onClick={() => handleSelectDays("19")} className={`boxMonth ${returnBoxClassDays("19")}`}>19</div>
                                    <div onClick={() => handleSelectDays("20")} className={`boxMonth ${returnBoxClassDays("20")}`}>20</div>
                                </div>
                            </div>
                            <button onClick={handleSave} className="buttonSave">SALVAR</button>
                        </div>
                    </S.CaixaAlteracao>
                </>)}
            </S.SaquesControllerContainer>
        </>
    )
}